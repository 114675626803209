<template>
  <div class="container">
    <Header :active="6" />
    <div class="banner-box">
      <el-image
        class="banner-box-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/banner.png"
        lazy
      ></el-image>
      <div class="banner-box-text-box">
        <div class="banner-box-text-box-title">
          <div>交流研究</div>
        </div>
        <div class="banner-box-text-box-tips">Communication research</div>
        <div
          class="horizontal-line"
          :style="{ marginTop: pxToRem(21), background: 'white' }"
        ></div>
      </div>
    </div>
    <div class="section1">
      <div class="section1-header-box">
        <div class="section1-header-box">
          <div class="section1-header-box-title">{{navDesc ? navDesc : '行业研究'}}</div>
          <div class="section1-header-box-tips">
            <div class="section1-header-box-tips-text">{{navDesc ? 'News reports' : 'Industry research'}}</div>
          </div>
          <div
            class="horizontal-line"
            :style="{ marginTop: pxToRem(21), marginBottom: pxToRem(40) }"
          ></div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/communication' }"
              >交流研究</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{navDesc ? navDesc : '行业研究'}}</el-breadcrumb-item>
            <el-breadcrumb-item class="industryTitle" :title="title">{{title}}</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="articleCont">
            <div class="pdf">
              <pdf
                v-for="currentPage in pageCount"
                :key="currentPage"
                :src="src"
                :page="currentPage"
                @loaded="loadPdfHandler"
              >
              </pdf>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
export default {
  name: "industryResearch",
  components: {
    pdf,
  },
  data() {
    return {
      currentPage: 1, // pdf文件页码
      pageCount: 12, // pdf文件总页数
      src: "/static/pdf/1.pdf", // pdf文件地址
      title: '',
      navDesc: '',
      industryList: [{
        title: 'Incidence of ADL Disability in Older Persons,Physical Activities as a Protective Factor and the Need for Informal and Formal Care – Results from the SNAC-N Project',
        currentPage: 1,
        pageCount: 12,
        src: "/static/pdf/1.pdf"
      }, {
        title: 'Artificial Intelligence Powered Digital Health Platform and Wearable Devices Improve Outcomes for Older Adults in Assisted Living Communities  Pilot Intervention Study',
        currentPage: 1,
        pageCount: 11,
        src: "/static/pdf/2.pdf"
      }, {
        title: 'Health management and pattern analysis of daily living activities of people with dementia using in-home sensors and machine learning techniques',
        currentPage: 1,
        pageCount: 20,
        src: "/static/pdf/3.pdf"
      }, {
        title: 'Novel sensing technology in fall risk assessment in older adults: a systematic review',
        currentPage: 1,
        pageCount: 10,
        src: "/static/pdf/4.pdf"
      }, {
        title: 'Digital health: a new dimension in rheumatology patient care',
        currentPage: 1,
        pageCount: 9,
        src: "/static/pdf/5.pdf"
      }, {
        title: 'Incidence of ADL Disability in Older Persons, Physical Activities as a Protective Factor and the Need for Informal and Formal Care – Results from the SNAC-N Project',
        currentPage: 1,
        pageCount: 12,
        src: "/static/pdf/6.pdf"
      }, {
        title: 'Associations between Depressive State and Impaired Higher-Level Functional Capacity in the Elderly with Long-Term Care Requirements',
        currentPage: 1,
        pageCount: 10,
        src: "/static/pdf/7.pdf"
      }, {
        title: 'Eating patterns and type 2 diabetes risk in older women: breakfast consumption and eating frequency1–3',
        currentPage: 1,
        pageCount: 8,
        src: "/static/pdf/8.pdf"
      }, {
        title: 'Relationship between Eating Disturbance and Dementia Severity in Patients with Alzheimer’s Disease',
        currentPage: 1,
        pageCount: 10,
        src: "/static/pdf/9.pdf"
      }, {
        title: 'Reliability of Objectively Measured Sedentary Time and Physical Activity in Adults',
        currentPage: 1,
        pageCount: 13,
        src: "/static/pdf/10.pdf"
      }, {
        title: 'Physical Activity and Different Concepts of Fall Risk Estimation in Older People–Results of the ActiFE-Ulm Study',
        currentPage: 1,
        pageCount: 11,
        src: "/static/pdf/11.pdf"
      }, {
        title: 'Innovative Assisted Living Tools, Remote Monitoring Technologies, Artificial Intelligence-Driven Solutions, and Robotic Systems for Aging Societies: Systematic Review',
        currentPage: 1,
        pageCount: 16,
        src: "/static/pdf/12.pdf"
      }, {
        title: 'AIoT 时代的智能无线感知：特征、算法、数据集',
        currentPage: 1,
        pageCount: 7,
        src: "/static/pdf/13.pdf"
      }]
    };
  },
  methods: {
    handleValueToStr(value) {
      switch (value) {
        case "a0b923820dcc509a":
          return 1;
        case "9d4c2f636f067f89":
          return 2;
        case "4b5ce2fe28308fd9":
          return 3;
        case "a2f3e71d9181a67b":
          return 4;
        case "bbce2345d7772b06":
          return 5;
        case "5a880faf6fb5e608":
          return 6;
        case "ceea167a5a36dedd":
          return 7;
        case "fb98ab9159f51fd0":
          return 8;
        case "2e2d7fbdea1afc51":
          return 9;
        case "02a44259755d38e6":
          return 10;
        case "d9caa6e02c990b0a":
          return 11;
        case "6fe97759aa27a0c9":
          return 12;
        case "c124a10e0db5e4b9":
          return 13;
      }
    },
    loadPdfHandler() {
      this.currentPage = 1; // 加载的时候先加载第一页
    },
  },
  mounted() {
    let num = this.handleValueToStr(this.$route.query.id) - 1;
    this.navDesc = this.$route.query.navDesc
    this.title = this.industryList[num].title
    this.src = pdf.createLoadingTask(this.industryList[num].src)
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #eef1f5;
}
.horizontal-line {
  width: 73px;
  height: 5px;
  background: #90c446;
}
.title-box {
  .title {
    font-family: "UI-Bold";
    font-size: 52px;
    color: #1f3876;
  }
  .subtitle {
    font-family: "CN-Light";
    font-size: 31px;
    margin-top: 5px;
    color: #90c446;
  }
  .info {
    display: flex;
    margin-top: 29px;
    &-left {
      margin-right: 30px;
      img {
        width: 23px;
        height: 24px;
      }
    }
    &-right {
      flex: 1;
      font-family: "CN-Light";
      font-size: 21px;
      color: #727171;
    }
  }
}
.banner-box {
  position: relative;
  &-img {
    width: 100%;
  }
  &-text-box {
    position: absolute;
    // width: 1200px;
    box-sizing: border-box;
    // height: 300px;
    left: 360px;
    // right: 0;
    top: 128px;
    // margin: 0 auto;
    color: white;
    &-title {
      font-size: 64px;
      font-family: "UI-Bold";
    }
    &-tips {
      font-family: "CN-Light";
      font-size: 31px;
      margin-top: 17px;
    }
    &-info {
      font-family: "CN-Light";
      line-height: 40px;
      color: #213763;
      width: 427px;
    }
    &-btn {
      width: 120px;
      height: 30px;
      margin-top: 60px;
      cursor: pointer;
    }
    &-btn:hover {
      transform: scale(1.02);
      transition: all 0.5s;
    }
  }
}
.section1 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  &-header-box {
    margin-left: 30px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
      font-weight: bold;
    }
    &-tips {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &-text {
        font-size: 31px;
        font-family: "CN-Light";
        color: #90c446;
        margin-top: 5px;
      }
      &-morecont {
        a {
          font-size: 21px;
          color: #727171;
          text-decoration: none;
          padding: 0 18px;
          border-left: 3px solid #90c446;
          font-weight: bold;
        }
        img {
          width: 102px;
          height: 20px;
        }
      }
    }
  }
}
.pdf {
  width: 100%;
  height: 100%;
  padding: 80px 0;
}
.industryTitle{
  /deep/.el-breadcrumb__inner{
    width: 800px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
